import { number } from 'prop-types';
import React from 'react';
import CountUp from 'react-countup';

const CountTwo = ({numbers}) => {
    return(
        <div className="homepage-numbers-two m-auto">
            {numbers.map((n, index) => (
                <React.Fragment key={n.title}>
                    <div className="providers-numbers">
                        <CountUp className='counter ' start={0} duration={2.5} end={n.number} />
                        <p className="fw-bold small-font-mob">{n.title}</p>
                    </div>
                    {index === 0 && <div className="providers-numbers"><p className="fw-bold small-font-mob">WITH</p></div>}
                    {index === 1 && <div className="providers-numbers"><p className="fw-bold small-font-mob">ACROSS</p></div>}
                    {/* {index === 2 && <div className="providers-numbers"><p className="fw-bold">FOR</p></div>} */}
                </React.Fragment>
            ))}
        </div>
    );
}

export default CountTwo;