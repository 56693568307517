import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ ]

    removeStock(event) {
      event.preventDefault()

      fetch(event.currentTarget.href, {
        method: 'GET',
        headers: {'Accept': 'text/plain' }
      })
      .then(response => response.text())
      .then((data) => {
        console.log(data)
        this.element.remove()
      })
    }
}