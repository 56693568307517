import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  connect() {
    // alert('Hello, Stimulus!')
  }

  toggle() {
    const video = this.element

    if (video.style.position === 'fixed') {
      // Reset video styles
      video.style.position = ''
      video.style.zIndex = ''
      video.style.width = ''
      video.style.height = ''
      video.style.top = ''
      video.style.left = ''
    } else {
      // Expand and center video
      video.style.position = 'fixed'
      video.style.zIndex = '999999999'
      if (window.innerWidth > 768) {
        video.style.width = '80%'
        video.style.height = '95%'
        video.style.top = '1%'
        video.style.left = '10%'
      } else {
        video.style.width = '95%'
        video.style.height = '60%'
        video.style.top = '10%'
        video.style.left = '2.5%'
      }
    }
  }
}